"use client";
import React, { useState } from "react";
import SideBarStyles from "./SidebarStyles";
import SubItem from "./SubItem/SubItem";
import SmallLogo from "./image/logo";
import ArrowRight from "./image/ArrowRight";
import TopNavBarMobile from "./TopNavBarMobile/TopNavBarMobile";
import Link from "next/link";
import { MdClose } from "react-icons/md";

const SideBar = ({ setActiveSideBar, lang, translations, navData }) => {
  const [activeSubItem, setActiveSubItem] = useState(-1);
  return (
    <SideBarStyles>
      {/* first column */}
      <div className="wrapper-first">
        {/* Logo */}
        <SmallLogo />
        <div onClick={() => setActiveSideBar(false)}>
          <MdClose className="icon-close" />
        </div>
      </div>
      {/* Tab */}
      <ul>
        {/* List Tab */}
        {navData?.map((tab, index) => {
          return (
            <React.Fragment key={index}>
              {index !== 7 ? (
                tab.isLink ? (
                  <li>
                    <Link
                      prefetch
                      className="wrapper-link"
                      href={`/${lang}/${tab.slug}/`}
                    >
                      {tab.label}
                    </Link>
                  </li>
                ) : (
                  <li
                    className="tab"
                    key={index}
                    onClick={() => setActiveSubItem(index)}
                  >
                    {tab.label} <ArrowRight />
                    {index == activeSubItem && (
                      <SubItem
                        tab={tab}
                        setActiveSubItem={setActiveSubItem}
                        lang={lang}
                        translations={translations}
                      />
                    )}
                  </li>
                )
              ) : (
                <li>
                  <Link
                    prefetch
                    className="wrapper-link"
                    href={`/${lang}/${tab.slug}/`}
                  >
                    {tab.label}
                  </Link>
                </li>
              )}
            </React.Fragment>
          );
        })}
      </ul>
      {/* SubItems Tab */}
      {navData?.map((tab, index) => {
        return (
          <React.Fragment key={index}>
            {index == activeSubItem && (
              <SubItem
                tab={tab}
                setActiveSubItem={setActiveSubItem}
                setActiveSideBar={setActiveSideBar}
                lang={lang}
                translations={translations}
              />
            )}
          </React.Fragment>
        );
      })}
      {/* TopNavBar for Mobile (signin, support, phone, lang) */}
      <TopNavBarMobile
        lang={lang}
        translations={translations}
        setActiveSideBar={setActiveSideBar}
      />
    </SideBarStyles>
  );
};

export default SideBar;
