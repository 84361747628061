import TabStyles from "./TabStyles";
import React from "react";
import Link from "next/link";
import SearchIcon from "../Search/icon";
import { NavItemsType } from "@/src/lib/navbar";
import Search from "../Search/Search";
import BlockColumn from "../BlockColumns/BlockColumns";

interface ITabProps {
  activeTab: number | undefined;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  navBgScroll: boolean;
  lang: string;
  navData: NavItemsType;
  translations: any;
}

const Tab = (props: ITabProps) => {
  // // Call navbar Function
  const { activeTab, setActiveTab, navBgScroll, lang, navData, translations } =
    props;

  return (
    <TabStyles $navBgScroll={navBgScroll}>
      {/*Container Tabs */}
      <div className="container-tab" onMouseLeave={() => setActiveTab(-1)}>
        {/* List of Tabs */}
        <div className="Tab-list">
          {/* Filter all Tabs based on your local Language then map */}
          {navData?.map((tab, index) => {
            return (
              <React.Fragment key={index}>
                {/* Tab */}
                {index !== navData.length - 1 ? (
                  <div
                    key={index}
                    className={`${
                      index === activeTab ? " Tab active-tab" : "Tab"
                    }`}
                    onMouseOver={() => setActiveTab(index)}
                  >
                    {/* Put label for all otherwise if search position add Icon Search */}
                    {index !== navData.length - 2 ? (
                      tab.label
                    ) : (
                      <SearchIcon
                        iconColor={
                          !navBgScroll
                            ? `${index === activeTab ? "#fff" : "#000"}`
                            : `#fff`
                        }
                      />
                    )}
                  </div>
                ) : (
                  //  Talk to us direct link
                  <Link
                    prefetch
                    className="Tab"
                    style={{ cursor: "pointer" }}
                    href={`/${lang}/${tab.slug}/`}
                    onMouseOver={() => setActiveTab(-1)}
                  >
                    {tab.label}
                  </Link>
                )}
                {/* Tab Panel  */}
                {/* for talk to us doesn't need tab panel */}
                {index == activeTab && index !== navData.length - 1 && (
                  <div
                    className={
                      index !== navData.length - 2
                        ? "Tab-panel"
                        : "Tab-panel bg-Search"
                    }
                  >
                    {/* If label is not Search */}
                    {tab.label !== "Search" ? (
                      <BlockColumn infoTab={tab} lang={lang} />
                    ) : (
                      <Search lang={lang} translations={translations} />
                    )}
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </TabStyles>
  );
};

export default Tab;
